import { FileServiceBuckets, FileServiceIncludeTypes } from "@constants/config";

const actionTypePrefix = "FILE/";

export const uploadActions = {
  UPLOAD_FILE: `${actionTypePrefix}UPLOAD_FILE`,
  UPLOAD_FILE_SUCCESS: `${actionTypePrefix}UPLOAD_FILE_SUCCESS`,
  UPLOAD_FILE_FAIL: `${actionTypePrefix}UPLOAD_FILE_FAIL`,

  UPLOAD_IMAGE: `${actionTypePrefix}UPLOAD_IMAGE`,
  UPLOAD_IMAGE_SUCCESS: `${actionTypePrefix}UPLOAD_IMAGE_SUCCESS`,
  UPLOAD_IMAGE_FAIL: `${actionTypePrefix}UPLOAD_IMAGE_FAIL`,
};


export interface IUploadSuccess {
  fileId?: string;
  fileName?: string;
  publicUrl?: string;
  sasUrl?: string;
}

export interface IFileData {
  uuid: string,
  url: string,
}

export interface IUploadFileParams {
  bucket: FileServiceBuckets;
  file: any;
  fileId?: string;
  include?: FileServiceIncludeTypes;
  onUploadSuccess?: (result: IUploadSuccess) => void;
  onUploadFail?: (error: string) => void;
}

export interface IUploadFileAction {
  type: string;
  payload: IUploadFileParams
}

export const uploadFile = (payload: IUploadFileParams): IUploadFileAction => {
  return {
    type: uploadActions.UPLOAD_FILE,
    payload
  };
};

export const uploadFileSuccess = (payload: {
  fileId: string,
}) => {
  return {
    type: uploadActions.UPLOAD_FILE_SUCCESS,
    payload
  };
};

export const uploadFileFailure = (payload: {error: string, fileId: string}) => {
  return {
    type: uploadActions.UPLOAD_FILE_FAIL,
    payload
  };
};


export interface IUploadImageParams {
  bucket: FileServiceBuckets;
  image: any;
  thumbnailAspectRatio?: number;
  imageId?: string;
  include?: FileServiceIncludeTypes;
  onUploadSuccess?: (result: IUploadSuccess) => void;
  onUploadFail?: (error: string) => void;
}

export interface IUploadImageAction {
  type: string;
  payload: IUploadImageParams;
}

export const uploadImage = (payload: IUploadImageParams): IUploadImageAction => {
  return {
    type: uploadActions.UPLOAD_IMAGE,
    payload
  };
};

export const uploadImageSuccess = (payload: {
  imageId: string,
}) => {
  return {
    type: uploadActions.UPLOAD_IMAGE_SUCCESS,
    payload: payload
  };
};

export const uploadImageFailure = (error: string) => {
  return {
    type: uploadActions.UPLOAD_IMAGE_FAIL,
    payload: error,
  };
};

