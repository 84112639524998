import { envType } from './ConfigConstant';
import DevConstant from './DevConstant.json';
import ProdConstant from './ProdConstant.json';
import StageConstant from './StageConstant.json';

const config: { [key: string]: any } = {
    dev: {
        ...DevConstant,
    },
    stage: {
        ...StageConstant,
    },
    prod: {
        ...ProdConstant,
    },
    demo: {
        ...DevConstant
    },
    preprod: {
        ...ProdConstant,
        "BASE_URL": "https://perfapi.opportunity.wfglobal.org",
        "BASE_URL_COMMON": "https://perfapi.wfcommon.wfglobal.org",
        "WEB_URL": "https://perfweb.opportunity.wfglobal.org/",
        "ADMIN_CONSOLE": "https://perfadmin.opportunity.wfglobal.org",
        "LMS_REDIRECTION_URL": "https://mok.ha.wfglobal.org/local/wfglobal/logout.php",
        "ZOOM_REDIRECT_URI": "https://perfweb.opportunity.wfglobal.org/en/settings/account/zoomSetting",
        "ZOOM_CLIENT_ID": "AxjxVwDfRlurehvSY2Ak4w",
        "LMS_COOKIES_REMOVE": "https://mok.ha.wfglobal.org/lib/ajax/service-nologin.php?args=[%7B%22index%22:0,%22methodname%22:%22wfglobal_get_clear_cookies%22,%22args%22:%20%7B%7D%7D]",
        "LMS_ACTIVITY_URL": "https://mok.ha.wfglobal.org/mod/",
        "LMS_URL": "https://mok.ha.wfglobal.org",
        "PUBLIC_CERTIFICATE_URL": "https://perfweb.certificate.wfglobal.org",
    }
};

// Note - envType. will be used to configure Project environment
// Change to envType.dev => dev environment
// Change to envType.stage => stage environment
// Change to envType.prod => Prod environment
// For demo environment we are using the dev environment credentials

export default config[process.env.REACT_APP_ENV || envType.dev];

