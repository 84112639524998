import { IFluxStandardAction } from '@store/webInterfaces';
import { IOptionsData, IInstituteAdminDashboardData, IInstituteAdminDashboardPayload, IInstituteAdminData, IInstituteAdminDashboardOpenCoursePayload } from '@redux/instituteAdmin/interface';

const actionTypePrefix = 'INSTITUTE_ADMIN';

export const instituteAdminActions = {
    FETCH_ORGANISATION_ADMIN_ID: `${actionTypePrefix}/FETCH_ORGANISATION_ADMIN_ID`,
    FETCH_ORGANISATION_ADMIN_ID_SUCCESS: `${actionTypePrefix}/FETCH_ORGANISATION_ADMIN_ID_SUCCESS`,
    FETCH_ORGANISATION_ADMIN_ID_FAIL: `${actionTypePrefix}/FETCH_ORGANISATION_ADMIN_ID_FAIL`,
    FETCH_ORGANISATION_ADMIN_DATA: `${actionTypePrefix}/FETCH_ORGANISATION_ADMIN_DATA`,
    FETCH_ORGANISATION_ADMIN_DATA_SUCCESS: `${actionTypePrefix}/FETCH_ORGANISATION_ADMIN_DATA_SUCCESS`,
    FETCH_ORGANISATION_ADMIN_DATA_FAIL: `${actionTypePrefix}/FETCH_ORGANISATION_ADMIN_DATA_FAIL`,
    FETCH_ORGANISATION_DETAILS: `${actionTypePrefix}/FETCH_ORGANISATION_DETAILS_DATA`,
    FETCH_ORGANISATION_DETAILS_SUCCESS: `${actionTypePrefix}/FETCH_ORGANISATION_DETAILS_SUCCESS`,
    FETCH_ORGANISATION_DETAILS_FAIL: `${actionTypePrefix}/FETCH_ORGANISATION_DETAILS_FAIL`,

    FETCH_INSTITUTE_ADMIN_ID: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_ID`,
    FETCH_INSTITUTE_ADMIN_ID_SUCCESS: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_ID_SUCCESS`,
    FETCH_INSTITUTE_ADMIN_ID_FAIL: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_ID_FAIL`,
    FETCH_INSTITUTE_ADMIN_DATA: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_DATA`,
    FETCH_INSTITUTE_ADMIN_DATA_SUCCESS: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_DATA_SUCCESS`,
    FETCH_INSTITUTE_ADMIN_DATA_FAIL: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_DATA_FAIL`,
    FETCH_COURSES_IDS: `${actionTypePrefix}/FETCH_COURSES_IDS`,
    FETCH_COURSES_IDS_SUCCESS: `${actionTypePrefix}/FETCH_COURSES_IDS_SUCCESS`,
    FETCH_COURSES_IDS_FAIL: `${actionTypePrefix}/FETCH_COURSES_IDS_FAIL`,
    FETCH_COURSES_DATA: `${actionTypePrefix}/FETCH_COURSES_DATA`,
    FETCH_COURSES_DATA_SUCCESS: `${actionTypePrefix}/FETCH_COURSES_DATA_SUCCESS`,
    FETCH_COURSES_DATA_FAIL: `${actionTypePrefix}/FETCH_COURSES_DATA_FAIL`,
    FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA`,
    FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_SUCCESS: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_SUCCESS`,
    FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_FAIL: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_FAIL`,
    FETCH_FACULTY_IDS: `${actionTypePrefix}/FETCH_FACULTY_IDS`,
    FETCH_FACULTY_IDS_SUCCESS: `${actionTypePrefix}/FETCH_FACULTY_IDS_SUCCESS`,
    FETCH_FACULTY_IDS_FAIL: `${actionTypePrefix}/FETCH_FACULTY_IDS_FAIL`,
    FETCH_FACULTY_DATA: `${actionTypePrefix}/FETCH_FACULTY_DATA`,
    FETCH_FACULTY_DATA_SUCCESS: `${actionTypePrefix}/FETCH_FACULTY_DATA_SUCCESS`,
    FETCH_FACULTY_DATA_FAIL: `${actionTypePrefix}/FETCH_FACULTY_DATA_FAIL`,
    FETCH_LAST_REFRESHED_DATA: `${actionTypePrefix}/FETCH_LAST_REFRESHED_DATA`,
    FETCH_LAST_REFRESHED_DATA_SUCCESS: `${actionTypePrefix}/FETCH_LAST_REFRESHED_DATA_SUCCESS`,
    FETCH_LAST_REFRESHED_DATA_FAIL: `${actionTypePrefix}/FETCH_LAST_REFRESHED_DATA_FAIL`,
    FETCH_INSTITUTE_ADMIN_OPEN_COURSE_DATA: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_OPEN_COURSE_DATA`,
    FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_SUCCESS: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_SUCCESS`,
    FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_FAIL: `${actionTypePrefix}/FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_FAIL`,
    FETCH_OPEN_COURSES_DATA: `${actionTypePrefix}/FETCH_OPEN_COURSES_DATA`,
    FETCH_OPEN_COURSES_DATA_SUCCESS: `${actionTypePrefix}/FETCH_OPEN_COURSES_DATA_SUCCESS`,
    FETCH_OPEN_COURSES_DATA_FAIL: `${actionTypePrefix}/FETCH_OPEN_COURSES_DATA_FAIL`,
};

export interface IOpenCourseAction {
    type: string;
    payload: string;
}

export interface IOpenCourseDataAction {
    type: string;
    payload: IInstituteAdminDashboardOpenCoursePayload;
}

export const fetchOrganisationAdminId = (payload: string): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_ORGANISATION_ADMIN_ID,
    payload
});

export const fetchOrganisationAdminIdSuccess = (payload: string): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_ORGANISATION_ADMIN_ID_SUCCESS,
    payload
});

export const fetchOrganisationAdminIdFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_ORGANISATION_ADMIN_ID_FAIL,
    payload: error
});

export const fetchInstituteAdminId = (payload: string): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID,
    payload
});

export const fetchInstituteAdminIdSuccess = (payload: string): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID_SUCCESS,
    payload
});

export const fetchInstituteAdminIdFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID_FAIL,
    payload: error
});

export const fetchInstituteAdminData = (payload: string): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA,
    payload
});

export const fetchInstituteAdminDataSuccess = (payload: IInstituteAdminData): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA_SUCCESS,
    payload
});

export const fetchInstituteAdminDataFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA_FAIL,
    payload: error
});
export const fetchOrganisationAdminData = (payload: string[]): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_ORGANISATION_ADMIN_DATA,
    payload
});

export const fetchOrganisationAdminDataSuccess = (payload: any): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_ORGANISATION_ADMIN_DATA_SUCCESS,
    payload
});

export const fetchOrganisationAdminDataFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_ORGANISATION_ADMIN_DATA_FAIL,
    payload: error
});
export const fetchOrganisationDetails = (payload: string): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_ORGANISATION_DETAILS,
    payload
});

export const fetchOrganisationDetailsSuccess = (payload: any): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_ORGANISATION_DETAILS_SUCCESS,
    payload
});

export const fetchOrganisationDetailsFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_ORGANISATION_DETAILS_FAIL,
    payload: error
});

export const fetchCourseIds = (payload: string): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_COURSES_IDS,
    payload
});

export const fetchCourseIdsSuccess = (payload: string[]): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_COURSES_IDS_SUCCESS,
    payload
});

export const fetchCourseIdsFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_COURSES_IDS_FAIL,
    payload: error
});

export const fetchCourseData = (payload: string[]): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_COURSES_DATA,
    payload
});

export const fetchCourseDataSuccess = (payload: IOptionsData[]): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_COURSES_DATA_SUCCESS,
    payload
});

export const fetchCourseDataFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_COURSES_DATA_FAIL,
    payload: error
});

export const fetchInstituteAdminDashboardOpenCourseData = (payload: IInstituteAdminDashboardOpenCoursePayload): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_OPEN_COURSE_DATA,
    payload
});

export const fetchInstituteAdminDashboardOpenCourseDataSuccess = (payload: any): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_SUCCESS,
    payload
});

export const fetchInstituteAdminDashboardOpenCourseDataFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_OPEN_COURSE_DATA_FAIL,
    payload: error
});

export const fetchFacultyIds = (payload: string): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_FACULTY_IDS,
    payload
});

export const fetchFacultyIdsSuccess = (payload: string[]): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_FACULTY_IDS_SUCCESS,
    payload
});

export const fetchFacultyIdsFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_FACULTY_IDS_FAIL,
    payload: error
});

export const fetchFacultyData = (payload: string[]): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_FACULTY_DATA,
    payload
});

export const fetchFacultyDataSuccess = (payload: IOptionsData[]): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_FACULTY_DATA_SUCCESS,
    payload
});

export const fetchFacultyDataFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_FACULTY_DATA_FAIL,
    payload: error
});

export const fetchLastRefreshData = (): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_LAST_REFRESHED_DATA,
});

export const fetchLastRefreshDataSuccess = (payload: any): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_LAST_REFRESHED_DATA_SUCCESS,
    payload
});

export const fetchLastRefreshDataFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_LAST_REFRESHED_DATA_FAIL,
    payload: error
});

export const fetchInstituteAdminDashboardData = (payload: IInstituteAdminDashboardPayload): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA,
    payload
});

export const fetchInstituteAdminDashboardDataSuccess = (payload: any): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_SUCCESS,
    payload
});

export const fetchInstituteAdminDashboardDataFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA_FAIL,
    payload: error
});

export const fetchOpenCourseData = (payload: string): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_OPEN_COURSES_DATA,
    payload
});

export const fetchOpenCourseDataSuccess = (payload: IOptionsData[]): IFluxStandardAction<any> => ({
    type: instituteAdminActions.FETCH_OPEN_COURSES_DATA_SUCCESS,
    payload
});

export const fetchOpenCourseDataFail = (error: string): IFluxStandardAction<string> => ({
    type: instituteAdminActions.FETCH_OPEN_COURSES_DATA_FAIL,
    payload: error
});

