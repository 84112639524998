import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { Tabs, Dropdown, Menu } from 'antd';
import { APP_MENU_ITEM } from "@constants/config";

import Navigator from "@navigator/index";

import { IAppMenuItem } from "@layout/Dashboard";
import "./styles.less";
import { WFImg } from "@components/index";

const { TabPane } = Tabs;

interface IProps {
  tabItems: IAppMenuItem[];
  className?: string;
  activeKey: string;
  onChange: (key: string) => void;
  tabPosition?: string;
  size?: any;
}

export const TabView = (props: IProps) => {
  const { activeKey, size, className, tabItems, onChange } = props;
  let selectedKey = activeKey;
  if(selectedKey === APP_MENU_ITEM.PITCH_TRAINER) {
    selectedKey = APP_MENU_ITEM.PLACEMENT_PREP;
  }
  
  const { t } = useTranslation();

  const style = ["tab_container", className].join(" ");

  const tabItemClicked = (item: string) => {
    props.onChange(item);
  };

  const menu = (items) => (
    <>
      <Menu>
        {items.map(eachItem => {
          const onClick = () => {
            const route = eachItem.url;
            Navigator.push(route);
          };
          return (
            <Menu.Item key={eachItem.key} onClick={onClick}>{eachItem.name}</Menu.Item>
          )
        })}
      </Menu>
    </>
  );

  const renderTabValue = (tabItem, onClick) => {
    return(
    <div className="tab_menu" onClick={onClick} id={`mobile_${tabItem.name.toString().split(" ")[0]}`}>
            {tabItem?.inactiveImage ? <WFImg src={selectedKey === APP_MENU_ITEM.PLACEMENT_PREP ? tabItem?.activeImage : tabItem?.inactiveImage} /> : tabItem.iconImage}
            <p>{tabItem.name.toString().split(" ")[0]}</p>
            <p>{tabItem.name.toString().split(" ")[1]}</p>
          </div>
  )};

  const renderTabData = (tabItem) => {
    const onClick = () => {
      const route = tabItem.url;
      Navigator.push(route);
      onChange(tabItem.key);
    };
    return (
      <>
        {tabItem?.children?.length > 0 ? (<>
          <span>
            <Dropdown overlay={menu(tabItem?.children)} trigger={['click']} overlayClassName="tab-custom-dropdown">
            {renderTabValue(tabItem, ()=>{})}
            </Dropdown>
          </span>
        </>) :renderTabValue(tabItem, onClick)}
      </>
    )
  };

  return (
    <Tabs
      className={style}
      onChange={tabItemClicked}
      activeKey={selectedKey}
      size={size}
      centered
    >
      {tabItems.map((tabItem: IAppMenuItem) => {
        const onClick = () => {
          const route = tabItem.url;
          Navigator.push(route);
          onChange(tabItem.key);
        };

        return (
          <TabPane
            key={tabItem.key}
            tab={renderTabData(tabItem)}
          />
        );
      })}
    </Tabs>
  );
};

export default memo(TabView);
