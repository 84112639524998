import React, { memo } from 'react';

import { Menu } from 'antd';
import { useMediaQuery } from '@utils/CustomHooks';

import Img from "@components/atoms/Img";
import WFMenuItem from '@components/Menu';

import Navigator from '@navigator/index';

import { IAppMenuItem } from '@layout/Dashboard';
import { DownOutlined, EllipsisOutlined } from '@ant-design/icons';

import './style.less';

interface IProps {
  menuItems: IAppMenuItem[];
  className?: string;
  onMenuChange: (key: string, index?: number) => void;
  selectedKey: string;
}

function DashBoardMenu(props: IProps) {
  const { selectedKey, menuItems, onMenuChange } = props;
  const isMobile = useMediaQuery("(max-width: 767px)");
  const renderImage = (iconImage) => {
    switch (iconImage) {
      case 'LibraryIcon':
        return null
      default:
        break;
    }
  };

  const renderMenuItem = (menuItem) => {
    const onClick = () => {
      const route = menuItem.url;
      Navigator.push(route);
      onMenuChange(menuItem.key);
    };
    return (
    <WFMenuItem key={menuItem.key} onClick={onClick}>
      <div
        className="vertical_menu"
        id={`${menuItem.name.toString().split(" ")[0]}`}
      >
        {menuItem.iconImage && (
          <Img
            alt="menu icon"
            src={renderImage(menuItem.iconImage)}
            className="icon_image"
          />
        )}
        {menuItem.name}
      </div>
    </WFMenuItem>
  )};

  return (
    <div className="dashboard_menu_container">
      <Menu
        mode="horizontal"
        theme="dark"
        defaultSelectedKeys={[menuItems[0].key]}
        selectedKeys={[selectedKey]}
        overflowedIndicator={<EllipsisOutlined className="overflow-icon"/>}
        triggerSubMenuAction={isMobile ? "click" : "hover"}
      >
        {menuItems.map((menuItem: IAppMenuItem) => {
          return menuItem?.children?.length > 0 ? (
            <Menu.SubMenu
              key={menuItem.key} // Use the correct key here
              title={
                <span>
                  <span>{menuItem.name}</span>
                  <DownOutlined style={{ marginLeft: 8 }} />
                </span>
              }
            >
              {menuItem?.children?.map((subMenu) => (
                renderMenuItem(subMenu)
              ))}
            </Menu.SubMenu>
          ) : renderMenuItem(menuItem);
        })}
      </Menu>
    </div>

  );
}

export default memo(DashBoardMenu);
