export enum envType {
    dev = 'dev',
    stage = 'stage',
    prod = 'prod',
    demo = 'demo',
    preprod = 'preprod'
};

export const LOCATION_DEFAULT = "India";

export const LANGUAGE_DEFAULT = "English";

export const TOTAL_PERCENTAGE = 100;

export const COL_WIDTH = 150;

export const contentType = {
    mentor: 'mentor',
    tutor: 'tutor',
    Mentor: 'Mentor',
    Tutor: 'Tutor'
};

export const courseCustomText = {
    india: 'India'
};

export const userFeedbackType = {
    UPVOTE: "upVote",
    DOWNVOTE: "downVote",
}

export const countriesName = {
    MEXICO: "Mexico"
}