import { deserializeJsonObj, deserializeJsonObjCollection } from '@utils/ObjectMapperUtil';

import { APIv1 } from "@common/network/constants";
import { ApiClient, wfcommonApiClient } from "@network/client";
import { IPitchResponse, PitchTrainerModel } from '../models/PitchTrainer';
import { getLoggedInUserId } from '@utils/UserUtils';
import { IGetPitchById } from '@common/redux/pitchTrainer/actions';
import { PitchTrainerPrompt } from '@common/domain/models/PitchTrainerPrompt';

const ENDPOINTS = {
    createPitch: () => `${APIv1}/pitchtrainerservice/response`,
    getPitch: (isPublicPitch) => isPublicPitch ? `public${APIv1}/pitchtrainerservice/responses` : `${APIv1}/pitchtrainerservice/responses`,
    updatePitch: (id) => `${APIv1}/pitchtrainerservice/response/${id}`,
    getPromptDependencies: () => `${APIv1}/pitchtrainerservice/prompts?language=default`,
};

class PitchTrainerRepository {
    private apiClient: any;
    private commonApiClient: any;

    constructor(apiClient: any, commonApiClient: any) {
        this.apiClient = apiClient;
        this.commonApiClient = commonApiClient;
    }

    public createPitch = async (payload: IPitchResponse) => {
        const response = await this.apiClient.post(ENDPOINTS.createPitch(), payload);
        const createPitchResponse = deserializeJsonObj(response, PitchTrainerModel, 'createPitch');
        return createPitchResponse;
    }

    public getPitch = async (payload: IGetPitchById) => {
        const { isPublicPitch } = payload;
        const customPayload = {
            ...!isPublicPitch && {userId: getLoggedInUserId()},
            ...payload
        };
        const response = await this.apiClient.get(ENDPOINTS.getPitch(isPublicPitch), customPayload);
        const getPitchResponse = deserializeJsonObjCollection(response?.data, PitchTrainerModel, 'getPitch');
        return getPitchResponse;
    }

    public updatePitch = async (payload: IPitchResponse) => {
        const response = await this.apiClient.patch(ENDPOINTS.updatePitch(payload.id), payload);
        const updatePitchResponse = deserializeJsonObj(response, PitchTrainerModel, 'updatePitch');
        return updatePitchResponse;
    }

    public deletePitch = async (payload: string) => {
        const response = await this.apiClient.delete(ENDPOINTS.updatePitch(payload));
        const deletePitchResponse = deserializeJsonObj(response, PitchTrainerModel, 'deletePitch');
        return deletePitchResponse;
    }

    public getPromptDependecies = async () => {
        const response = await this.apiClient.get(ENDPOINTS.getPromptDependencies());
        const getPromptDependeciesResponse = deserializeJsonObj(response?.data[0], PitchTrainerPrompt, 'getPromptDependecies');
        return getPromptDependeciesResponse;
    }

}


const pitchTrainerRepository = new PitchTrainerRepository(ApiClient, wfcommonApiClient);

export { pitchTrainerRepository as PitchTrainerRepository };
