
import { ProfileModel } from "@common/domain/models/Profile";
import { DataUtility } from '@utils/DataUtility';

import { userRoles } from "@constants/config";

export interface IIncompleteField {
  fieldName: string;
  message: string;
}

export type FieldVisibilityMap = { [fieldName: string]: boolean };

export const EXPERT_TYPE = [{
  value: "GENERAL MENTORS", label: "General Mentors", key: "GENERAL MENTORS",
}, {
  value: "INDUSTRY MENTORS", label: "Industry Mentors", key: "GENERAL MENTORS",
}, {
  value: "JOB-ROLE MENTORS", label: "Job-Role Mentors", key: "GENERAL MENTORS",
},];

export const EXPERT_TYPE_VALUES = {
  "GENERAL MENTORS": "GENERAL MENTORS",
  "INDUSTRY MENTORS": "INDUSTRY MENTORS",
  "JOB-ROLE MENTORS": "JOB-ROLE MENTORS",
}

export const workExperienceRequiredField = ["employerName", "employmentSector", "startDate"];
export const validateFields = (requiredField, validObj) => {
  let isValidated = true;
  let invalidKeys = {};
  for (let i = 0; i < requiredField.length; i++) {
    if (DataUtility.isEmpty(validObj?.[requiredField[i]])) {
      isValidated = false;
      invalidKeys[requiredField[i]] = true;
    }
  }
  return { isValidated, invalidKeys };
}

export const USER_FIELD_NAMES = Object.freeze({
  profilePic: "profilePic",
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  language: "language",
  dob: "dob",
  gender: "gender",
  education: "education",
  phone: "phone",
  linkedIn: "linkedIn",
  facebook: "facebook",
  twitter: "twitter",
  bio: "bio",
  location: "location",
  summary: "summary",
  workExperience: "workExperience",
  spokenLanguage: "spokenLanguage"
});

export const profileMetaDataKey = {
  PROGRAMS: "programs",
  PROFILE_LANGUAGES: "profile-languages",
  EXPERT_SECTOR: "expert-sectors",
  EXPERT_JOB_ROLE: "expert-job-roles",
  WORK_EXPERIENCE: "workexperience-filter"
}

/**
   * 
      common required fields 
          firstName
          lastName
          cityName
          phone.countryCode
          phone.number
          language

      For Students
          birthYear 
          gender
          education
* 
*/


type ProfileField = {
  fieldName: string;
  fieldData: any;
  message: string;
}

export const initialAreFieldsEditted = {
  firstName: false,
  lastName: false,
  gender: false,
  organisationId: false,
  phoneNumber: false,
  city: false,
  bio: false,
  linkedIn: false,
  education: false,
  yearOfBirth: false,
  calcomUrl: false,
  language: false,
  spokenLanguage: false,
  workExperience: false,
  expertType: false,
  sector: false,
  jobRole: false,
  employmentDetails: false,
  heading: false,
  facebook: false,
  twitter: false,
}

const requiredCommonFields = (profile: ProfileModel): ProfileField[] => {
  let requiredFields: ProfileField[] = [
    {
      fieldName: USER_FIELD_NAMES.firstName,
      fieldData: profile.getFirstName(),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.lastName,
      fieldData: profile.getLastname(),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.location,
      fieldData: profile?.getLocation && profile?.getLocation() && (profile?.getLocation()?.city || profile?.getLocation()?.country),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.language,
      fieldData: profile.getPreferredLanguage(),
      message: '',
    },
  ]
  if (profile.getRole() === userRoles.expert) {
    requiredFields = [...requiredFields,
    {
      fieldName: USER_FIELD_NAMES.spokenLanguage,
      fieldData: profile.getLanguagesKnown(),
      message: '',
    }, {
      fieldName: USER_FIELD_NAMES.workExperience,
      fieldData: profile.getWorkExperience(),
      message: '',
    },
    ]
  }
  return requiredFields;
}

export const flattenGroupValues = (groupedArr) => {
  const result = [];
  groupedArr?.forEach(group => {
    group.groupValues.forEach(value => {
      result.push({ groupName: group.groupName, value: value });
    });
  });

  return result;
}

export const getRequiredIncompleteFields = (
  profile: ProfileModel
): {
  incompleteFields: IIncompleteField[];
  visibilityMap: FieldVisibilityMap;
} => {
  const incompleteFields: IIncompleteField[] = [];
  const visibilityMap: FieldVisibilityMap = {};
  const requiredFields = requiredCommonFields(profile);

  requiredFields.forEach((field: ProfileField) => {
    if (DataUtility.isEmpty(field.fieldData)) {
      incompleteFields.push({
        fieldName: field.fieldName,
        message: field.message,
      });
      visibilityMap[field.fieldName] = true;
    }

  })

  return { incompleteFields, visibilityMap };
};

export const POSTREGISTRATION_SCREENS = {
  BASICDETAILS: "BasicDetails",
  PLACEMENTDETAILS: "PlacementData",
  PLACEMENTDETAILSPHASE2: "PlacementDataP2",
}

export const PLACEMENT_STATUS = {
  UNEMPLOYED: "UNEMPLOYED",
  STUDENT_EMPLOYED: "STUDENT_EMPLOYED",
  STUDENT: "STUDENT",
  EMPLOYED: "EMPLOYED"
}


const profileConfig = {
  STUDENT: {
    screens: {
      BasicDetails: {
        mandatoryFields: ['phoneNumber'],
        optionalFields: ['gender', 'yearOfBirth'],
      },
      PlacementData: {
        mandatoryFields: [
          'aspiringSectors',
          'placementStatus',
          'educationDetails',
          'employmentDetails',
        ],
        optionalFields: [],
      },
      // Add more screens for students if needed
    },
    redirectOrder: ['BasicDetails', 'PlacementData'], // Order of screens for redirection
  },
  FACULTY: {
    screens: {
      BasicDetails: {
        mandatoryFields: ['phoneNumber'],
        optionalFields: ['gender', 'yearOfBirth'],
      },
      PlacementData: {
        mandatoryFields: [
          'aspiringSectors',
          'placementStatus',
          'educationDetails',
          'employmentDetails',
        ],
        optionalFields: [],
      },
      // Add more screens for students if needed
    },
    redirectOrder: ['BasicDetails', 'PlacementData'], // Order of screens for redirection
  }
  // Add configurations for other personas if needed
};

export const duration = {
  STUDENT: {
    MAX_DAYS: 30 * 24 * 60 * 60 * 1000,
    MIN_DAYS: 1 * 24 * 60 * 60 * 1000,
    // This time added for Already registered users.
    RELEASE_DATE: "2024-04-18T06:15:16.997Z",
    PHASE_RELEASE_DATE : "2024-11-25T13:09:27.031Z"
  },
  FACULTY: {
    MAX_DAYS: 30,
    MIN_DAYS: 2,
  },
};

// Function to get mandatory fields for a given screen and persona
const getMandatoryFields = (screen, persona) => {
  return profileConfig[persona]?.screens[screen]?.mandatoryFields || [];
};

// Function to determine if a screen is mandatory for a given persona
const isMandatoryScreen = (screen, persona) => {
  const mandatoryFields = getMandatoryFields(screen, persona);
  return Array.isArray(mandatoryFields) && mandatoryFields.length > 0;
};

// Function to check if a user's profile is complete for a specific screen
const isProfileCompleteForScreen = (profile, screen, persona) => {
  const mandatoryFields = getMandatoryFields(screen, persona);
  return mandatoryFields.every(field => profile[field]);
};

// Function to get the first incomplete mandatory field in a screen
export const getFirstIncompleteMandatoryField = (profile, screen, persona) => {
  const mandatoryFields =
    profileConfig[persona]?.screens[screen]?.mandatoryFields || [];

  for (const field of mandatoryFields) {
    if (!profile[field]) {
      return field; // Return the first incomplete mandatory field
    }
  }

  return null; // Return null if all mandatory fields are completed
};

// Function to check if a user's profile is complete
export const isProfileComplete = (profile, persona) => {
  const screens = profileConfig[persona]?.screens || {};
  const allMandatoryFields = Object.values(screens).flatMap(
    //@ts-ignore
    screen => screen?.mandatoryFields,
  );
  return allMandatoryFields.every(field => profile[field]);
};

// Function to get the screen to redirect to based on profile completeness and redirect order
export const getRedirectScreen = (profile, persona) => {
  const redirectOrder = profileConfig[persona]?.redirectOrder || [];
  const eligibleScreens = redirectOrder.filter(screen =>
    isMandatoryScreen(screen, persona),
  );

  for (const screen of eligibleScreens) {
    if (!isProfileCompleteForScreen(profile, screen, persona)) {
      return screen; // Return the first incomplete mandatory screen
    }
  }

  return null; // Return null if all mandatory screens are complete or none are mandatory
};

// Function to check if a user needs to be redirected to complete their profile
export const shouldRedirectToProfileCompletion = (
  profile,
  persona,
  daysSinceRegistration,
) => {
  if (daysSinceRegistration <= duration[persona]?.MIN_DAYS) {
    return false;
  }
  if (daysSinceRegistration > duration[persona]?.MIN_DAYS) {
    return !isProfileComplete(profile, persona);
  }
};

// Method to calculate days since registration from createdAt object in profile
export const calculateDaysSinceRegistration = createdAt => {
  const registrationDate = new Date(createdAt);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - registrationDate.getTime();
  return timeDifference;
  // return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};


export const showForcedPopup = (persona, daysSinceRegistration) => {
  if (daysSinceRegistration > duration[persona]?.MAX_DAYS) {
    // Show force popup if more than 30 days have passed
    return true;
  }
  return false;
}


export const isUserCreatedBeforeRelease = (persona, createdAt) => {
  const releaseDate = new Date(duration[persona]?.RELEASE_DATE);
  const createdDate = new Date(createdAt);
  return createdDate?.getTime() < releaseDate?.getTime();
}

export const convertJobRoletoPayloadFormat = (selectedItems) => {
  // Initialize a map to group values by groupName
  const groupedMap = new Map();

  selectedItems.forEach(item => {
    const { groupName, value } = item;
    if (!groupedMap.has(groupName)) {
      groupedMap.set(groupName, []);
    }
    groupedMap.get(groupName).push(value);
  });

  // Convert the map back to the desired array format
  const result = [];
  groupedMap.forEach((groupValues, groupName) => {
    result.push({ groupName, groupValues });
  });

  return result;
}

const MS_IN_A_DAY = 24 * 60 * 60 * 1000;

export const getDisplayStatus = (graduationDate, userprofileData) => {
  const today = new Date();
  const gradDate = new Date(graduationDate);
  const lastUpdated = userprofileData?.educationorEmployementDataUpdatedOn ? new Date(userprofileData?.educationorEmployementDataUpdatedOn) : null;
  const threeMonthsAfterGrad = new Date(gradDate.getFullYear(), gradDate.getMonth() + 3, 1);
  const endOfThreeMonthsAfterGrad = new Date(threeMonthsAfterGrad.getFullYear(), threeMonthsAfterGrad.getMonth() + 1, 0); // Last day of this month

  // Set graduation date to the 1st of the month
  gradDate.setDate(1);

  // Case 1: 30 days before graduation date until the end of the month
  const thirtyDaysBeforeGrad = new Date(gradDate.getFullYear(), gradDate.getMonth() - 1, 1);
  const endOfGradMonth = new Date(gradDate.getFullYear(), gradDate.getMonth(), 0); // Last day of graduation month

  const oneYearOfGraduationDate = new Date(gradDate.getFullYear(), gradDate.getMonth() + 12, 1);
  if (today > oneYearOfGraduationDate) {
    return { showBanner: false, showPopup: false };
  }

  if (userprofileData?.educationDetails?.length > 0) {
    if (today >= thirtyDaysBeforeGrad && today <= endOfGradMonth && !userprofileData?.employmentDetails[0]?.employmentType) {
      if (!lastUpdated) {
        return { showBanner: true, showPopup: false };
      } else {
        return { showBanner: false, showPopup: false };
      }
    }
    if (today > endOfGradMonth && !userprofileData?.employmentDetails[0]?.employmentType && !lastUpdated) {
      return { showBanner: false, showPopup: true };
    }
    if (lastUpdated > thirtyDaysBeforeGrad && lastUpdated < threeMonthsAfterGrad && today < threeMonthsAfterGrad) {
      return { showBanner: false, showPopup: false };
    }
    // Case 2: 3 months after graduation date until the end of that month
    if (!userprofileData?.employmentDetails[0]?.employmentType && today >= threeMonthsAfterGrad && today <= endOfThreeMonthsAfterGrad) {
      return { showBanner: true, showPopup: false };
    }
    if (!userprofileData?.employmentDetails[0]?.employmentType && today > endOfThreeMonthsAfterGrad && (lastUpdated >= threeMonthsAfterGrad && lastUpdated <= endOfThreeMonthsAfterGrad)) {
      return { showBanner: false, showPopup: true };
    }

    // Case 3: 30 days after the last updated date, if userprofileData provided again
    if (!userprofileData?.employmentDetails[0]?.employmentType && userprofileData?.educationorEmployementDataUpdatedOn && today > endOfThreeMonthsAfterGrad) {
      const thirtyDaysAfterLastUpdate = new Date(lastUpdated.getTime() + MS_IN_A_DAY * 30);
      const endOfThirtyDaysPeriod = new Date(thirtyDaysAfterLastUpdate.getTime() + MS_IN_A_DAY * 30);

      if (today >= thirtyDaysAfterLastUpdate && today <= endOfThirtyDaysPeriod) {
        return { showBanner: true, showPopup: false };
      }
      if (today > endOfThirtyDaysPeriod) {
        return { showBanner: false, showPopup: true };
      }
    }
  }

  // Default: No banner or popup
  return { showBanner: false, showPopup: false };
};

export const getRemainingDaysInMonth = () => {
  const today: Date = new Date();
  const currentYear: number = today.getFullYear();
  const currentMonth: number = today.getMonth();

  // Get the last day of the current month
  const lastDayOfMonth: Date = new Date(currentYear, currentMonth + 1, 0);

  const remainingDays: number = (lastDayOfMonth.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);

  return Math.ceil(remainingDays);
}
