import { call, ForkEffect, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { getErrorCodes } from '@utils/ErrorMessageUtils';

import { PitchTrainerRepository } from '@common/domain/repositories/PitchTrainerRepository';

import { IFluxStandardAction } from '@store/webInterfaces';
import { createPitchFailure, createPitchSuccess, deletePitchFailure, deletePitchSuccess, getPitchFailure, getPitchSuccess, getPromptDependeciesFailure, getPromptDependeciesSuccess, pitchTrainerActions, updatePitchFailure, updatePitchSuccess } from './actions';

export function* createPitchSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(PitchTrainerRepository.createPitch, action.payload);
        yield put(createPitchSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(createPitchFailure(error));
    }
}

export function* getPitchSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(PitchTrainerRepository.getPitch, action.payload);
        yield put(getPitchSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(getPitchFailure(error));
    }
}

export function* updatePitchSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(PitchTrainerRepository.updatePitch, action.payload);
        yield put(updatePitchSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(updatePitchFailure(error));
    }
}

export function* deletePitchSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(PitchTrainerRepository.deletePitch, action.payload);
        yield put(deletePitchSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(deletePitchFailure(error));
    }
}

export function* getPromptDependenciesSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(PitchTrainerRepository.getPromptDependecies);
        yield put(getPromptDependeciesSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(getPromptDependeciesFailure(error));
    }
}



export function* watchPitchTrainer(): Generator<ForkEffect> {
    yield takeLatest(pitchTrainerActions.CREATE_PITCH, createPitchSaga);
    yield takeLatest(pitchTrainerActions.GET_PITCH, getPitchSaga);
    yield takeLatest(pitchTrainerActions.UPDATE_PITCH, updatePitchSaga);
    yield takeLatest(pitchTrainerActions.DELETE_PITCH, deletePitchSaga);
    yield takeLatest(pitchTrainerActions.GET_PROMPT_DEPENDENCIES, getPromptDependenciesSaga);
}
