import { JsonProperty } from "json-object-mapper";

export interface IDependencies {
    key: string;
    source: string;
    _id: string;
}


export class PitchTrainerPrompt {

  @JsonProperty({ name: "deleted", required: false })
  public deleted: boolean = false;

  @JsonProperty({ name: "prompt", required: true })
  public prompt: string = "";

  @JsonProperty({ name: "updatedAt", required: false })
  public updatedAt: string = "";

  @JsonProperty({ name: "dependencies", required: true })
  public dependencies: IDependencies[] = [];

  @JsonProperty({ name: "language", required: false })
  public language: string = "";

  @JsonProperty({ name: "id", required: false })
  public id: string = "";

  @JsonProperty({ name: "createdAt", required: false })
  public createdAt: string = "";

  public getDeleted(): boolean {
    return this.deleted;
  }

  public getPrompt(): string {
    return this.prompt;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }

  public getDependencies(): IDependencies[] {
    return this.dependencies;
  }

  public getLanguage(): string {
    return this.language;
  }

  public getId(): string {
    return this.id;
  }

  public getCreatedAt(): string {
    return this.createdAt;
  }
}
