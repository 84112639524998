import { programActions } from '@redux/program/actions';
import { IProgramState } from '@redux/program/interface';

export const initialState = {
    byId: {},
    programDetail: null,
    programList: [],
    programCount: 0,
    enrolledProgramList: [],
    exploreProgramList: [],
    courseProgramList: [],
    instituteProgramList: [],
    instituteProgramCount: 0,
    wadhwaniProgramList: [],
    wadhwaniProgramCount: 0,
    loaders: {
        programDetail: false,
        programList: false,
        enrollProgram: false,
        enrolledProgramList: false,
        exploreProgramList: false,
        courseProgramList: false,
        instituteProgramList: false,
        wadhwaniProgramList: false,
    },
    errors: {
        programDetail: "",
        programList: "",
        enrollProgram: "",
        enrolledProgramList: "",
        exploreProgramList: "",
        courseProgramList: "",
        instituteProgramList: '',
        wadhwaniProgramList: '',
    }
}

export const programReducer = (state: IProgramState = initialState,
    action: { type: string; payload: any }) => {
    switch (action.type) {

        // program detail
        case programActions.PROGRAM_DETAIL:
            return {
                ...state,
                loaders: { ...state.loaders, programDetail: true },
                errors: { ...state.errors, programDetail: '' },
            }
        case programActions.PROGRAM_DETAIL_SUCCESS:
            return {
                ...state,
                byId: { ...state.byId, [action.payload.id || action.payload._id]: action.payload },
                programDetail: action.payload,
                loaders: { ...state.loaders, programDetail: false },
            }
        case programActions.PROGRAM_DETAIL_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, programDetail: false },
                errors: { ...state.errors, programDetail: action.payload },
            }
        // program list
        case programActions.PROGRAM_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, programList: true },
                errors: { ...state.errors, programList: '' },
            }
        case programActions.PROGRAM_LIST_SUCCESS:
            return {
                ...state,
                programList: action.payload.programList,
                programCount: action.payload.total,
                loaders: { ...state.loaders, programList: false },
            }
        case programActions.PROGRAM_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, programList: false },
                errors: { ...state.errors, programList: action.payload },
            }
        // enroll program
        case programActions.ENROLL_PROGRAM:
            return {
                ...state,
                loaders: { ...state.loaders, enrollProgram: true },
                errors: { ...state.errors, enrollProgram: '' },
            }
        case programActions.ENROLL_PROGRAM_SUCCESS:
            return {
                ...state,
                byId: { ...state.byId, [action.payload.id]: action.payload },
                loaders: { ...state.loaders, enrollProgram: false },
            }
        case programActions.ENROLL_PROGRAM_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, enrollProgram: false },
                errors: { ...state.errors, enrollProgram: action.payload },
            }
        // enrolled program list
        case programActions.ENROLLED_PROGRAM_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, enrolledProgramList: true },
                errors: { ...state.errors, enrolledProgramList: '' },
            }
        case programActions.ENROLLED_PROGRAM_LIST_SUCCESS:
            return {
                ...state,
                enrolledProgramList: action.payload,
                loaders: { ...state.loaders, enrolledProgramList: false },
            }
        case programActions.ENROLLED_PROGRAM_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, enrolledProgramList: false },
                errors: { ...state.errors, enrolledProgramList: action.payload },
            }
        // explore program list
        case programActions.EXPLORE_PROGRAM_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, exploreProgramList: true },
                errors: { ...state.errors, exploreProgramList: '' },
            }
        case programActions.EXPLORE_PROGRAM_LIST_SUCCESS:
            return {
                ...state,
                exploreProgramList: action.payload.programList,
                loaders: { ...state.loaders, exploreProgramList: false },
            }
        case programActions.EXPLORE_PROGRAM_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, exploreProgramList: false },
                errors: { ...state.errors, exploreProgramList: action.payload },
            }
        // course program list
        case programActions.COURSE_PROGRAM_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, courseProgramList: true },
                errors: { ...state.errors, courseProgramList: '' },
            }
        case programActions.COURSE_PROGRAM_LIST_SUCCESS:
            return {
                ...state,
                courseProgramList: action.payload.programList,
                loaders: { ...state.loaders, courseProgramList: false },
            }
        case programActions.COURSE_PROGRAM_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, courseProgramList: false },
                errors: { ...state.errors, courseProgramList: action.payload },
            }
        // institute program list
        case programActions.INSTITUTE_PROGRAM_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, instituteProgramList: true },
                errors: { ...state.errors, instituteProgramList: '' },
            };
        case programActions.INSTITUTE_PROGRAM_LIST_SUCCESS:
            return {
                ...state,
                instituteProgramList:
                    action.payload.pageNumber === 1
                        ? action.payload.programList
                        : [...state.instituteProgramList, ...action.payload.programList],
                instituteProgramCount: action.payload.total,
                loaders: { ...state.loaders, instituteProgramList: false },
            };
        case programActions.INSTITUTE_PROGRAM_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, instituteProgramList: false },
                errors: { ...state.errors, instituteProgramList: action.payload },
            };
        // wadhwani program list
        case programActions.WADHWANI_PROGRAM_LIST:
            return {
                ...state,
                loaders: { ...state.loaders, wadhwaniProgramList: true },
                errors: { ...state.errors, wadhwaniProgramList: '' },
            };
        case programActions.WADHWANI_PROGRAM_LIST_SUCCESS:
            return {
                ...state,
                wadhwaniProgramList:
                    action.payload.pageNumber === 1
                        ? action.payload.programList
                        : [...state.wadhwaniProgramList, ...action.payload.programList],
                wadhwaniProgramCount: action.payload.total,
                loaders: { ...state.loaders, wadhwaniProgramList: false },
            };
        case programActions.WADHWANI_PROGRAM_LIST_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, wadhwaniProgramList: false },
                errors: { ...state.errors, wadhwaniProgramList: action.payload },
            };
        default:
            return state;
    }
}





