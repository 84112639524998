import { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import qs from 'qs';

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef<T>();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
};

export function useQuery()  {
    return new URLSearchParams(useLocation().search);
}
export function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const matchQueryList = window.matchMedia(query);
      if(matchQueryList.matches){
        setMatches(true)
      }
      function handleChange(e) {
        setMatches(e.matches);
      }
      matchQueryList.addEventListener("change", handleChange);
      return () => {
        matchQueryList.removeEventListener("change", handleChange);
      };
    }, [query]);
    return matches;
  }

export const getQueryParam = (search) => {
    return qs.parse(search.slice(1));
}