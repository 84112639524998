import { IPitchResponse, PitchTrainerModel } from "@common/domain/models/PitchTrainer";
import { PitchTrainerPrompt } from "@common/domain/models/PitchTrainerPrompt";

const actionTypePrefix = "PITCH_TRAINER/";

export const pitchTrainerActions = {
    CREATE_PITCH: `${actionTypePrefix}CREATE_PITCH`,
    CREATE_PITCH_SUCCESS: `${actionTypePrefix}CREATE_PITCH_SUCCESS`,
    CREATE_PITCH_FAIL: `${actionTypePrefix}CREATE_PITCH_FAIL`,

    GET_PITCH: `${actionTypePrefix}GET_PITCH`,
    GET_PITCH_SUCCESS: `${actionTypePrefix}GET_PITCH_SUCCESS`,
    GET_PITCH_FAIL: `${actionTypePrefix}GET_PITCH_FAIL`,

    UPDATE_PITCH: `${actionTypePrefix}UPDATE_PITCH`,
    UPDATE_PITCH_SUCCESS: `${actionTypePrefix}UPDATE_PITCH_SUCCESS`,
    UPDATE_PITCH_FAIL: `${actionTypePrefix}UPDATE_PITCH_FAIL`,

    DELETE_PITCH: `${actionTypePrefix}DELETE_PITCH`,
    DELETE_PITCH_SUCCESS: `${actionTypePrefix}DELETE_PITCH_SUCCESS`,
    DELETE_PITCH_FAIL: `${actionTypePrefix}DELETE_PITCH_FAIL`,

    GET_PROMPT_DEPENDENCIES: `${actionTypePrefix}GET_PROMPT_DEPENDENCIES`,
    GET_PROMPT_DEPENDENCIES_SUCCESS: `${actionTypePrefix}GET_PROMPT_DEPENDENCIES_SUCCESS`,
    GET_PROMPT_DEPENDENCIES_FAIL: `${actionTypePrefix}GET_PROMPT_DEPENDENCIES_FAIL`,
};

export interface IPitchAction {
    type: string;
    payload: IPitchResponse;
}

export interface IDeletePitchAction {
    type: string;
    payload: string;
}

export interface IGetPitchById {
    ids?: string[];
    isPublicPitch?: boolean;
}

export interface IGetPitchAction {
    type: string;
    payload: IGetPitchById;
};

export interface IPromptDependecyAction {
    type: string;
};

export const createPitch = (
    payload: IPitchResponse
): IPitchAction => {
    return {
        type: pitchTrainerActions.CREATE_PITCH,
        payload
    };
};

export const createPitchSuccess = (response) => {
    return {
        type: pitchTrainerActions.CREATE_PITCH_SUCCESS,
        payload: response,
    };
};

export const createPitchFailure = (error: string) => {
    return {
        type: pitchTrainerActions.CREATE_PITCH_FAIL,
        payload: error,
    };
};

export const getPitch = (
    ids: string[], isPublicPitch?: boolean
): IGetPitchAction => {
    return {
        type: pitchTrainerActions.GET_PITCH,
        payload: {
            ids,
            isPublicPitch
        }
    };
};

export const getPitchSuccess = (response: PitchTrainerModel[]) => {
    return {
        type: pitchTrainerActions.GET_PITCH_SUCCESS,
        payload: response,
    };
};

export const getPitchFailure = (error: string) => {
    return {
        type: pitchTrainerActions.GET_PITCH_FAIL,
        payload: error,
    };
};

export const updatePitch = (
    payload: IPitchResponse
): IPitchAction => {
    return {
        type: pitchTrainerActions.UPDATE_PITCH,
        payload
    };
};

export const updatePitchSuccess = (response) => {
    return {
        type: pitchTrainerActions.UPDATE_PITCH_SUCCESS,
        payload: response,
    };
};

export const updatePitchFailure = (error: string) => {
    return {
        type: pitchTrainerActions.UPDATE_PITCH_FAIL,
        payload: error,
    };
};

export const deletePitch = (
    payload: string
): IDeletePitchAction => {
    return {
        type: pitchTrainerActions.DELETE_PITCH,
        payload
    };
};

export const deletePitchSuccess = (response) => {
    return {
        type: pitchTrainerActions.DELETE_PITCH_SUCCESS,
        payload: response,
    };
};

export const deletePitchFailure = (error: string) => {
    return {
        type: pitchTrainerActions.DELETE_PITCH_FAIL,
        payload: error,
    };
};

export const getPromptDependecies = (): IPromptDependecyAction => {
    return {
        type: pitchTrainerActions.GET_PROMPT_DEPENDENCIES
    };
};

export const getPromptDependeciesSuccess = (response: PitchTrainerPrompt) => {
    return {
        type: pitchTrainerActions.GET_PROMPT_DEPENDENCIES_SUCCESS,
        payload: response,
    };
};

export const getPromptDependeciesFailure = (error: string) => {
    return {
        type: pitchTrainerActions.GET_PROMPT_DEPENDENCIES_FAIL,
        payload: error,
    };
};

