import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';
import { getErrorCodes } from '@utils/ErrorMessageUtils';
import { InstituteAdminRepository } from '@common/domain/repositories/InstituteAdminRepository';
import {
    instituteAdminActions,
    fetchInstituteAdminIdSuccess,
    fetchInstituteAdminIdFail,
    fetchInstituteAdminDataSuccess,
    fetchInstituteAdminDataFail,
    fetchCourseIdsSuccess,
    fetchCourseIdsFail,
    fetchCourseDataSuccess,
    fetchCourseDataFail,
    fetchInstituteAdminDashboardDataSuccess,
    fetchInstituteAdminDashboardDataFail,
    fetchFacultyIdsSuccess,
    fetchFacultyIdsFail,
    fetchFacultyDataSuccess,
    fetchFacultyDataFail,
    fetchLastRefreshDataSuccess,
    fetchLastRefreshDataFail,
    fetchInstituteAdminDashboardOpenCourseDataSuccess,
    fetchInstituteAdminDashboardOpenCourseDataFail,
    fetchOpenCourseDataSuccess,
    fetchOpenCourseDataFail,
    IOpenCourseAction,
    IOpenCourseDataAction,
    fetchOrganisationAdminIdFail,
    fetchOrganisationAdminIdSuccess,
    fetchOrganisationAdminDataSuccess,
    fetchOrganisationAdminDataFail,
    fetchOrganisationDetailsSuccess,
    fetchOrganisationDetailsFail
} from '@redux/instituteAdmin/action';
import { InstituteAdminCustomText } from '@presentation/Home/InstituteAdminDashboard/config';
import { i18n } from '@translations/i18n';
import { instituteAdminMessages } from '@containers/InstituteAdmin/messages';

export function* fetchOrganisationAdminIdSaga(value): Generator<StrictEffect, void, any> {
    try {
        const fetchOrganisationAdminIdResponse = yield call(InstituteAdminRepository.fetchOrganisationId, value.payload);
        const orgIdArray = fetchOrganisationAdminIdResponse.organisationAdmins.map(admin => admin.organisationId);
        yield put(fetchOrganisationAdminIdSuccess(orgIdArray));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchOrganisationAdminIdFail(error));
    }
};

export function* fetchInstituteAdminIdSaga(value): Generator<StrictEffect, void, any> {
    try {
        const fetchInstituteAdminIdResponse = yield call(InstituteAdminRepository.fetchInstituteId, value.payload);
        yield put(fetchInstituteAdminIdSuccess(fetchInstituteAdminIdResponse?.instituteAdmins[0]?.instituteId));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchInstituteAdminIdFail(error));
    }
};

export function* fetchInstituteAdminDataSaga(value): Generator<StrictEffect, void, any> {
    try {
        const fetchInstituteAdminDataResponse = yield call(InstituteAdminRepository.fetchInstituteData, value.payload);
        yield put(fetchInstituteAdminDataSuccess(fetchInstituteAdminDataResponse?.institutes[0]));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchInstituteAdminDataFail(error));
    }
};
export function* fetchOrganisationAdminDataSaga(value): Generator<StrictEffect, void, any> {
    try {
        const fetchOrganisationAdminDataResponse = yield call(InstituteAdminRepository.fetchOrganisationData, value.payload);
        const instituteIdArray = fetchOrganisationAdminDataResponse.organisationInstitutes.map(item => item.instituteId);
        if(instituteIdArray.length > 0){
        const instituteDetails = yield call(InstituteAdminRepository.fetchMultipleInstituteData, instituteIdArray);
        yield put(fetchOrganisationAdminDataSuccess(instituteDetails?.institutes));
        }else{
            yield put(fetchOrganisationAdminDataSuccess([]));
        }
        
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchOrganisationAdminDataFail(error));
    }
};
export function* fetchOrganisationDetailsSaga(value): Generator<StrictEffect, void, any> {
    try {
        const organisationDetails = yield call(InstituteAdminRepository.organisationDetails, value.payload);
        yield put(fetchOrganisationDetailsSuccess(organisationDetails?.organisations));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchOrganisationDetailsFail(error));
    }
};

export function* fetchCoursesIdsSaga(value): Generator<StrictEffect, void, any> {
    try {
        const filterCourseIds = [];
        const courseResponse = yield call(InstituteAdminRepository.fetchCourseIds, value.payload);
        if (courseResponse && courseResponse.length > 0) {
            courseResponse.forEach(eachCourse => {
                if (filterCourseIds.indexOf(eachCourse.courseId) === -1) {
                    filterCourseIds.push(eachCourse.courseId);
                }
            });
            yield put(fetchCourseIdsSuccess(filterCourseIds));
        }

    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchCourseIdsFail(error));
    }
};

export function* fetchCoursesDataSaga(value): Generator<StrictEffect, void, any> {
    try {
        const filteredCourseData = [];
        const coursesDataResponse = yield call(InstituteAdminRepository.fetchCoursesData, value.payload);
        if (coursesDataResponse && coursesDataResponse.length > 0) {
            coursesDataResponse.forEach(eachCourse => {
                filteredCourseData.push({ id: eachCourse.courseRootId, name: eachCourse.name, courseId: eachCourse.id });
            });
            yield put(fetchCourseDataSuccess(filteredCourseData));
        }

    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchCourseDataFail(error));
    }
};

export function* fetchOpenCoursesDataSaga(value: IOpenCourseAction): Generator<StrictEffect, void, any> {
    try {
        const filteredCourseData = [];
        const coursesDataResponse = yield call(InstituteAdminRepository.fetchOpenCoursesData, value.payload);
        if (coursesDataResponse && coursesDataResponse.length > 0) {
            coursesDataResponse.forEach(eachCourse => {
                filteredCourseData.push({ id: eachCourse.course_root_id, name: eachCourse.course_root_name });
            });
            yield put(fetchOpenCourseDataSuccess(filteredCourseData));
        }

    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchOpenCourseDataFail(error));
    }
};

export function* fetchInstituteAdminDashboardDataSaga(value): Generator<StrictEffect, void, any> {
    try {
        const fetchInstituteAdminDashboardDataResponse = yield call(InstituteAdminRepository.fetchInstituteDashboardData, value.payload);
        if (fetchInstituteAdminDashboardDataResponse && fetchInstituteAdminDashboardDataResponse?.data) {
            yield put(fetchInstituteAdminDashboardDataSuccess(fetchInstituteAdminDashboardDataResponse.data));
        }
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchInstituteAdminDashboardDataFail(error));
    }
};

export function* fetchInstituteAdminDashboardOpenCourseDataSaga(value: IOpenCourseDataAction): Generator<StrictEffect, void, any> {
    try {
        const fetchInstituteAdminDashboardOpenCourseDataResponse = yield call(InstituteAdminRepository.fetchInstituteDashboardOpenCourseData, value.payload);
        if (fetchInstituteAdminDashboardOpenCourseDataResponse && fetchInstituteAdminDashboardOpenCourseDataResponse?.data) {
            yield put(fetchInstituteAdminDashboardOpenCourseDataSuccess(fetchInstituteAdminDashboardOpenCourseDataResponse.data));
        }
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchInstituteAdminDashboardOpenCourseDataFail(error));
    }
};

export function* fetchFacultyIdsSaga(value): Generator<StrictEffect, void, any> {
    try {
        const filterFacultyIds = [];
        const facultyResponse = yield call(InstituteAdminRepository.fetchFacultyIds, value.payload);
        if (facultyResponse && facultyResponse.length > 0) {
            facultyResponse.forEach(eachCourse => {
                if (filterFacultyIds.indexOf(eachCourse.userId) === -1) {
                    filterFacultyIds.push(eachCourse.userId);
                }
            });
            yield put(fetchFacultyIdsSuccess(filterFacultyIds));
        }

    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchFacultyIdsFail(error));
    }
};

export function* fetchFacultyDataSaga(value): Generator<StrictEffect, void, any> {
    try {
        const filteredFacultyData = [];
        const facultyDataResponse = yield call(InstituteAdminRepository.fetchFacultyData, value.payload);
        if (facultyDataResponse && facultyDataResponse.length > 0) {
            filteredFacultyData.push({ id: InstituteAdminCustomText.allFaculties, name: i18n.t(instituteAdminMessages.allFaculty.key) })
            facultyDataResponse.forEach(eachFaculty => {
                filteredFacultyData.push({ id: eachFaculty.userId, name: `${eachFaculty.firstName} ${eachFaculty.lastName}` });
            });
            yield put(fetchFacultyDataSuccess(filteredFacultyData));
        }

    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchFacultyDataFail(error));
    }
};

export function* fetchLastRefreshedDataSaga(): Generator<StrictEffect, void, any> {
    try {
        const lastRefreshDataResponse = yield call(InstituteAdminRepository.fetchLastRefreshedData);
        const lastRefreshedDate = lastRefreshDataResponse?.last_refreshed_date;
        yield put(fetchLastRefreshDataSuccess(lastRefreshedDate));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchLastRefreshDataFail(error));
    }
};


export function* watchInstituteAdmin(): Generator<ForkEffect> {
    yield takeLatest(instituteAdminActions.FETCH_ORGANISATION_ADMIN_ID, fetchOrganisationAdminIdSaga);
    yield takeLatest(instituteAdminActions.FETCH_INSTITUTE_ADMIN_ID, fetchInstituteAdminIdSaga);
    yield takeLatest(instituteAdminActions.FETCH_INSTITUTE_ADMIN_DATA, fetchInstituteAdminDataSaga);
    yield takeLatest(instituteAdminActions.FETCH_ORGANISATION_ADMIN_DATA, fetchOrganisationAdminDataSaga);
    yield takeLatest(instituteAdminActions.FETCH_COURSES_IDS, fetchCoursesIdsSaga);
    yield takeLatest(instituteAdminActions.FETCH_COURSES_DATA, fetchCoursesDataSaga);
    yield takeLatest(instituteAdminActions.FETCH_OPEN_COURSES_DATA, fetchOpenCoursesDataSaga);
    yield takeLatest(instituteAdminActions.FETCH_INSTITUTE_ADMIN_DASHBOARD_DATA, fetchInstituteAdminDashboardDataSaga);
    yield takeLatest(instituteAdminActions.FETCH_INSTITUTE_ADMIN_OPEN_COURSE_DATA, fetchInstituteAdminDashboardOpenCourseDataSaga);
    yield takeLatest(instituteAdminActions.FETCH_FACULTY_IDS, fetchFacultyIdsSaga);
    yield takeLatest(instituteAdminActions.FETCH_FACULTY_DATA, fetchFacultyDataSaga);
    yield takeLatest(instituteAdminActions.FETCH_LAST_REFRESHED_DATA, fetchLastRefreshedDataSaga);
    yield takeLatest(instituteAdminActions.FETCH_ORGANISATION_DETAILS, fetchOrganisationDetailsSaga);
}